@import url('https://fonts.googleapis.com/css2?family=Nunito&display=swap');

* {
     font-size: 22px;
    box-sizing: border-box;
}

html {
    font-family: 'Nunito', sans-serif;
    font-size: 22px;
}


.App {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  min-height: 100vh; 
  padding: 1rem 0.5rem;
}

section {
    width: 100%;
    max-width: 420px;
    min-height: 400px;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    padding: 1rem;
    border-radius: 7px;
}
.heading{
    font-style: normal;
    font-size: 18px;
}
form {
    display: flex;
    flex-direction: column;
    justify-content: space-evenly;
    flex-grow: 1;
    padding-bottom: 1rem;
}

a, a:visited {
    color: #fff;
}

input{
    /* font-size: 18px; */
    padding: 0.25rem;
    border-radius: 0.5rem;
    font-family: 'Nunito', sans-serif;
}

label,
button {
  margin-top: 1rem;
  position: absolute;
  left: 10px;
}

button {
  padding: 0.5rem;

}

.instructions {
    font-size: 0.75rem;
    border-radius: 0.5rem;
    background: #000;
    color: #fff;
    padding: 0.25rem;
    position: relative;
    bottom: -10px;
}

.instructions > svg {
    margin-right: 0.25rem;
}

.offscreen {
    position: absolute;
    left: -9999px;
}

.hide {
    display: none;
}

.valid {
    color: limegreen;
    margin-left: 0.25rem;
}

.invalid {
    color: red;
    margin-left: 0.25rem;
}

.errmsg {
    background-color: lightpink;
    color: firebrick;
    font-weight: bold;
    padding: 0.5rem;
    margin-bottom: 0.5rem;
}

.line {
    display: inline-block;
}