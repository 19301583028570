  .canvas-container {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    color:#3367d6;
    margin-top: 20px;
  }
  
  canvas {
    border-radius: 10px;
    cursor: crosshair;
    margin-top: 10px;
  }
  
  .buttons-container {
    display: flex;
    margin: 5px 35px;
    /* padding: 4px 37px; */
    gap:13px;
    position: relative;
    top: 0px;
    padding: 4px;
    left: 40px;
    
  }
  
  .button {
    margin: 4px 1px;
    padding: 11px 4px 10px 10px;
    background-color:#1c1c1c;
    font-weight: bold;
    color: #fff;
    border: none;
    border-radius: 5px;
    cursor: pointer;
    transition: background-color 0.3s, color 0.3s;
    height: 39px;
    width: 113px;
  }
  
  .button:hover {
    background-color: #2c522c; 
  }
  
  .button:active {
    background-color: #255abf; 
  }
  
  .animation {
    animation: fadeIn 0.5s ease-in-out;
  }
  
  @keyframes fadeIn {
    from {
      opacity: 0;
    }
    to {
      opacity: 1;
    }
  }
  