@import url("https://fonts.googleapis.com/css2?family=Roboto:wght@400;900&display=swap");
body{
    margin: 0;
    padding: 0;
    box-sizing: border-box;
    font-family: Roboto;
}
.container {
    background-color: #1c1c1c;
    font-family: 'Roboto';
    box-sizing: border-box;
    display: flex;
    flex-direction: column;
    min-height: 100vh;
    height: auto;
}


.send-to-sign-btn {
    display: flex;
    width: 9.813rem;
    height: 2.5rem;
    border-radius: 38.5px;
    background: linear-gradient(90deg, #00d322, #469ba6 50%, #006d12);
    /* margin-top: 0.4rem; */
}
.flex {
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.send-sign {
    color: white;
    font-weight: 900;
    /* font-size: 24px; */
    margin-left: 0.9rem;
}
.sendMsg3 {
    width: 1rem;
    height: 1.2rem;
    margin-right: 0.8rem;
    margin-left: 0.9rem;
}
.wrapper-container {
    display: flex;
    align-items: center;
    gap: 15px;
    margin-top: 0.5rem;
}
.wrapper {
    border: 3px solid #00d322;
    width: 12.813rem;
    height: 2.75rem;
    border-radius: 35px;
    /* cursor: pointer; */
}

.wrapper-content-main {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 13rem;
    height: 4rem;
}

.wrapper-content-main-img {
    width: 3rem;
    margin: -10px;
}

.wrapper-content {
    display: flex;
    align-items: center;
    gap: 1rem;
    padding: 1rem;
    border-radius: 38.5px;
    background-color: #1c1c1c;
    border: 3px solid #000;
    box-sizing: border-box;
    width: 13.625rem;
    height: 3.75rem;
    margin: 1rem;
}

.onespanlogo {
    width: 2.875rem;
    height: 2.438rem;
    margin-left: -0.6rem;
}

.sign {
    font-size: 24px;
    font-weight: 900;
    color: #fff;
}

.wrapper-name {
    display: flex;
    align-items: center;
    border-radius: 39.5px;
    background-color: #1c1c1c;
    border: 3px solid #00d322;
    width: 9.813rem;
    height: 2.5rem;
}

.user-image {
    border-radius: 21px;
    width: 2.625rem;
    height: 2.625rem;
    object-fit: cover;
    margin-left: 0.5rem;
}

.rowan {
    font-size: 24px;
    font-weight: 900;
    color: #fff;
    margin-left: 1rem;
}

.circle-parent {
    border-radius: 38.5px;
    background-color: #1c1c1c;
    border: 3px solid #00d322;
    box-sizing: border-box;
    width: 3.75rem;
    height: 3.75rem;
    margin-left: 1rem;
}

.vector {
    margin-left: 1rem;
    margin-top: 1rem;

}


.pdf-view {
    position: relative;
    background-color: white;
}

.pdf-with-talk {
    display: flex;
    justify-content: space-between;
    /* align-items: center; */
}


.talk {
    font-weight: 900;
}

.talk-pdf-content {
    height: 3.5rem;
    position: relative;
    font-weight: 900;
    display: inline-block;
    z-index: 1;
}

.pdf {
    margin: 0;
    color: #fff;
}

.suggestion {
    font-weight: 700;
    color: #fff;
}

.your-chat {
    color: white;
    padding: 1rem;
}

.send-chat-btn {
    display: flex;
    /* display: flex; */
    justify-content: space-between;
    align-items: center;
    border-radius: 38.5px;
    background-color: #151515;
    /* height: 3.75rem; */
    margin-top: 3rem;
}

.summersize {
    border-radius: 38.5px;
    border: 1px solid #fff;
    box-sizing: border-box;
    width: 14.25rem;
    height: 2.5rem;
    margin-top: 1rem;
    margin-bottom: 1rem;
}

.summersize1 {
    border-radius: 38.5px;
    border: 1px solid #fff;
    box-sizing: border-box;
    width: 15.875rem;
    height: 2.5rem;
}

.sum-para {
    color: white;
    margin: auto;
    padding: 0.7rem;
}

.react-pdf__Page__textContent textLayer{
    position: relative;
}
.react-pdf__Page__annotations annotationLayer{
    display: none;
}
.react-pdf__Page__textContent textLayer
{
    position: relative;
}
.react-pdf__Page__textContent {
    display: none;
}
.react-pdf__Page__annotations {
    display: none;
}
.react-pdf__Page {
    border: 20px;
}

.react-pdf__Page {
    border-radius: 20px;
}

.react-pdf__Page__annotations {
    display: none;
}
.react-pdf__Page__canvas{
    border-radius: 20px;
}
.loader {
    position: fixed;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    z-index: 9999;
    background: rgba(255, 255, 255, 0.7);
    padding: 20px;
    border-radius: 5px;
  }

  .text{
    background-color: #151515;
    border: none;
    color: white;
  }
  .react-pdf__Page__canvas{
    height: 1094px;
  }
  .canvas react-pdf__Page__canvas {
    height: 1094px;
  }
  /* canvas {
    height: 1094px;
  } */
  .react-pdf__Page__canvas {
    height: 1094px !important;
    /* width: 753px !important; */
    width: 796px !important;
    /* width: 763px !important; */
  }
  

.mysign {
    padding: 1.875rem 2.75rem 2rem;
}

.docs-chat {
    color: white;
    font-weight: 900;
    font-size: 24px;
    position: absolute;
    top: 1.89rem !important;
    left: 4rem;
}





.wrapper-content-main {
    display: flex;
    /* justify-content: center; */
    align-items: center;
    width: 9rem;
    height: 2.65rem;
    margin-left: 1rem;
}

.wrapper-content-main-img {
    width: 2.2rem;
}

.wrapper-content {
    display: flex;
    align-items: center;
    gap: 1rem;
    padding: 1rem;
    border-radius: 38.5px;
    background-color: #1c1c1c;
    border: 3px solid #000;
    box-sizing: border-box;
    width: 13.625rem;
    height: 3.75rem;
    margin: 1rem;
}

.onespanlogo {
    width: 2.875rem;
    height: 2.438rem;
    margin-left: -0.6rem;
}

.sign {
    font-size: 24px;
    font-weight: 900;
    color: #fff;
}


.user-image {
    border-radius: 21px;
    width: 2.625rem;
    height: 2.625rem;
    object-fit: cover;
    margin-left: 0.5rem;
}

.rowan {
    font-size: 24px;
    font-weight: 900;
    color: #fff;
    margin-left: 0.5rem;
}


.vector {
    margin-left: 1rem;
    margin-top: 1rem;

}


.clickable.show {
    display: block;
}

.pdf-view {
   
    border-radius: 35px;
    position: relative;
    width: 100%;
}

.pdf-with-talk {
    display: flex;
    justify-content: space-between;
    margin-top: 20px;
    gap: 1rem;
    /* align-items: center; */
}
.pdf-container{
    width: 796px;
}

.chat-container{
    width: 40%;
    height: auto;
}
.aiChat{
    color: white;
    text-align: center;
    font-size: 1.2rem;
    font-weight: 700;
}
.chat-data{
    height: 54vh;
    color: white;
    margin-top: 2rem;
    font-weight: 400;
}
.sendAi{
    width: 100%;
    /* border: 2px solid green; */
    display: flex;
    justify-content: space-between;
    align-items: center;
    gap: 1rem;
}

input {
    font-size: 21px;
    /* padding: 0.25rem; */
    border-radius: 0.5rem;
    font-family: 'Nunito', sans-serif;
    width: 100%;
    /* height: 4vh; */
    /* border: none; */
    border: 2px solid #00d322;
    outline: none;
    /* background: black; */
    padding: 0.7rem;
    border-radius: 2rem;
}


@media screen and (max-width : 767px) {
    .mysign {
        padding: 2.875rem 1.75rem 2rem;
    }

    .docs-chat {
        font-size: 16px;
        top: 3.9rem;
        left: 2.9rem;
    }

    .group1 {
        width: 8rem;
    }

    .send-to-sign-btn {
        width: 4rem;
        height: 2rem;
    }

    .send-sign {
        display: none;
    }

    .sendMsg3 {
        width: 1.25rem;
        height: 1.25rem;
        margin-top: -9px;
        margin-left: 8px;
    }

    .wrapper-container {
        justify-content: space-between;
    }

    .wrapper {
        width: 14rem;
    }

    .wrapper-content {
        width: 12rem;
        margin-top: 1rem;
        box-sizing: border-box;
    }
    .Signer{
      color: white;
    }

    .circle-parent {
        width: 3rem;
        height: 3rem;
    }

    .vector {
        width: 1.2rem;
        margin-top: 0.78rem;
        margin-left: 0.78rem;
    }

    .pdf-with-talk {
        flex-direction: column;
        align-items: normal;
        /* flex-wrap: wrap; */
    }

    .pdf-container {
        width: 100%;
    }

    .pdf-view {
        width: 100%;
    }

    .talk-content {
        width: 100%;
        text-align: center;
        margin-top: 2rem;
    }

    .plusA {
        width: 6rem;
        margin-bottom: 1rem;
    }

    .pdf {
        margin: 0;
        color: #fff;
        font-weight: 900;
    }

    .summersize {
        border-radius: 38.5px;
        border: 1px solid #fff;
        box-sizing: border-box;
        width: 12.25rem;
        height: 2.5rem;
        margin-top: 1rem;
        margin-bottom: 1rem;
    }

    .summersize1 {
        border-radius: 38.5px;
        border: 1px solid #fff;
        box-sizing: border-box;
        width: 13.875rem;
        height: 2.5rem;
    }

    .sum-para {
        color: white;
        margin: auto;
        padding: 0.5rem;
    }

    .suggestion-container {
        margin-top: 4rem;
    }

    .send-chat-btn {
        display: flex;
        justify-content: space-between;
        align-items: center;
        border-radius: 38.5px;
        background-color: #151515;
        /* height: 3.75rem; */
        margin-top: 3rem;
        padding: 1.3rem;
    }
}

@media screen and (min-width : 768px) and (max-width : 991px) {
    .mysign {
        padding: 2.87rem 2.75rem 2rem;
    }

    .wrapper-container {
        justify-content: space-between;
    }

    .pdf-container {
        margin-top: 4rem;
    }

    .talk-container {
        margin-top: 11rem;
    }

    .pdf-view {
        width: 26rem;
        height: 39rem;
    }

    .talk {
        margin-top: 1rem;
    }


    .suggestion {
        margin-top: 2rem;
    }

    .send-chat-btn {
        padding: 0.7rem;
    }
}

@media screen and (min-width : 992px) and (max-width : 1199px) {
    .pdf-view {
        width: 38rem;
        height: 43rem;
    }

    .talk {
        margin-top: 1rem;
    }

    .suggestion-container {
        margin-top: 3rem;
    }

    .send-chat-btn {
        margin-top: 5rem;
    }

}

@media screen and (min-width : 1200px) {
    .talk {
        margin-top: 1rem;
    }

    .suggestion-container {
        margin-top: 3rem;
    }
}

.wrapper-content-main {
    display: flex;
    /* justify-content: center; */
    align-items: center;
    width: 9rem;
    height: 2.65rem;
    margin-left: 1rem;
}

.wrapper-content-main-img {
    width: 2.2rem;
}

.wrapper-content {
    display: flex;
    align-items: center;
    gap: 1rem;
    padding: 1rem;
    border-radius: 38.5px;
    background-color: #1c1c1c;
    border: 3px solid #000;
    box-sizing: border-box;
    width: 13.625rem;
    height: 3.75rem;
    margin: 1rem;
}

.onespanlogo {
    width: 2.875rem;
    height: 2.438rem;
    margin-left: -0.6rem;
}

.sign {
    font-size: 24px;
    font-weight: 900;
    color: #fff;
}


.user-image {
    border-radius: 21px;
    width: 2.625rem;
    height: 2.625rem;
    object-fit: cover;
    margin-left: 0.5rem;
}

.rowan {
    font-size: 24px;
    font-weight: 900;
    color: #fff;
    margin-left: 0.5rem;
}


.vector {
    margin-left: 1rem;
    margin-top: 1rem;

}


.clickable.show {
    display: block;
}

.pdf-view {
   
    border-radius: 35px;
    position: relative;
    width: 796px;
}

.pdf-with-talk {
    display: flex;
    justify-content: space-between;
    margin-top: 20px;
    gap: 0.5rem;

}


.chat-container{
    width: 40%;
    height: auto;
}
.aiChat{
    color: white;
    text-align: center;
    font-size: 1.2rem;
    font-weight: 700;
}
/* .chat-data{
    height: 60vh;
    color: white;
    margin-top: 1rem;
    font-weight: 400;
} */
.sendAi{
    width: 100%;
    display: flex;
    align-items: center;
    gap: 0.4rem;
}

input {
    font-size: 21px;
    border-radius: 0.5rem;
    font-family: 'Nunito', sans-serif;
    width: 100%;
    border: 2px solid #00d322;
    outline: none;
    padding: 0.7rem;
    border-radius: 2rem;
    height: 2rem;
}

.options{
    width: 5%;
    /* border: 1px solid #006d12; */
    position: absolute;
}

@media screen and (max-width : 767px) {
    .mysign {
        padding: 2.875rem 1.75rem 2rem;
    }

    .docs-chat {
        font-size: 16px;
        top: 3.9rem;
        left: 2.9rem;
    }

    .group1 {
        width: 8rem;
    }

    .send-to-sign-btn {
        width: 4rem;
        height: 2rem;
    }

    .send-sign {
        display: none;
    }

    .sendMsg3 {
        width: 1.25rem;
        height: 1.25rem;
        margin-top: -9px;
        margin-left: 8px;
    }

    .wrapper-container {
        justify-content: space-between;
    }

    .wrapper {
        width: 14rem;
    }

    .wrapper-content {
        width: 12rem;
        margin-top: 1rem;
        box-sizing: border-box;
    }
    .Signer{
      color: white;
    }

    .circle-parent {
        width: 3rem;
        height: 3rem;
    }

    .vector {
        width: 1.2rem;
        margin-top: 0.78rem;
        margin-left: 0.78rem;
    }

    .pdf-with-talk {
        flex-direction: column;
        align-items: normal;
        /* flex-wrap: wrap; */
    }

    .pdf-container {
        width: 100%;
    }

    .pdf-view {
        width: 100%;
    }

    .talk-content {
        width: 100%;
        text-align: center;
        margin-top: 2rem;
    }

    .plusA {
        width: 6rem;
        margin-bottom: 1rem;
    }

    .pdf {
        margin: 0;
        color: #fff;
        font-weight: 900;
    }

    .summersize {
        border-radius: 38.5px;
        border: 1px solid #fff;
        box-sizing: border-box;
        width: 12.25rem;
        height: 2.5rem;
        margin-top: 1rem;
        margin-bottom: 1rem;
    }

    .summersize1 {
        border-radius: 38.5px;
        border: 1px solid #fff;
        box-sizing: border-box;
        width: 13.875rem;
        height: 2.5rem;
    }

    .sum-para {
        color: white;
        margin: auto;
        padding: 0.5rem;
    }

    .suggestion-container {
        margin-top: 4rem;
    }

    .send-chat-btn {
        display: flex;
        justify-content: space-between;
        align-items: center;
        border-radius: 38.5px;
        background-color: #151515;
        /* height: 3.75rem; */
        margin-top: 3rem;
        padding: 1.3rem;
    }
}

@media screen and (min-width : 768px) and (max-width : 991px) {
    .mysign {
        padding: 2.87rem 2.75rem 2rem;
    }

    .wrapper-container {
        justify-content: space-between;
    }

    .pdf-container {
        margin-top: 4rem;
    }

    .talk-container {
        margin-top: 11rem;
    }

    .pdf-view {
        width: 26rem;
        height: 39rem;
    }

    .talk {
        margin-top: 1rem;
    }


    .suggestion {
        margin-top: 2rem;
    }

    .send-chat-btn {
        padding: 0.7rem;
    }
}

@media screen and (min-width : 992px) and (max-width : 1199px) {
    .pdf-view {
        width: 38rem;
        height: 43rem;
    }

    .talk {
        margin-top: 1rem;
    }

    .suggestion-container {
        margin-top: 3rem;
    }

    .send-chat-btn {
        margin-top: 5rem;
    }

}

@media screen and (min-width : 1200px) {
    .talk {
        margin-top: 1rem;
    }

    .suggestion-container {
        margin-top: 3rem;
    }
}

.react-pdf__Page__annotations annotationLayer{
  display:none;
}

.react-pdf__Page__textContent textLayer{
    position: relative;
}
.react-pdf__Page__annotations annotationLayer{
    display: none;
}
.react-pdf__Page__textContent textLayer
{
    position: relative;
}
.react-pdf__Page__textContent {
    display: none;
}
.react-pdf__Page__annotations {
    display: none;
}
.react-pdf__Page {
    border: 20px;
}

.react-pdf__Page {
    border-radius: 20px;
}

.react-pdf__Page__annotations {
    display: none;
}

.react-pdf__Page__canvas{
    border-radius: 30px;
}

.group1{
    position: relative;
    top: -19px;
}