.plusDocsChat {
  position: relative;
  font-weight: 900;
}

.frameChild {
  height: 100%;
  /* width: calc(100% - 17.6px); */
  position: absolute;
  margin: 0 !important;
  top: 0;
  right: 17.6px;
  bottom: 0;
  left: 0;
  max-width: 100%;
  overflow: hidden;
  max-height: 100%;
  z-index: 1;
}

.plusDocsChatParent {
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  justify-content: flex-start;
  padding: 23px var(--padding-10xl) 11.199999999999989px;
  position: relative;
}

.signSmartSign {
  margin: 0;
  flex: 1;
  position: relative;
  font-size: inherit;
  font-weight: bolder;
  font-size: xxx-large;
  /* font-family: inherit; */
  display: inline-block;
  max-width: 100%;
  z-index: 1;
}

.signSmartPlusAI {
  align-self: stretch;
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  justify-content: flex-end;
  padding: 0 5px 0 0;
  box-sizing: border-box;
  max-width: 100%;
}

.signSmartPlusAIParent {
  width: 912px;
  display: flex;
  flex-direction: column;
  align-items: flex-end;
  justify-content: flex-start;
  gap: 46px;
  max-width: 100%;
}

.home,
.homeInner {
  display: flex;
  align-items: flex-start;
  box-sizing: border-box;
  color: var(--color-white);
  font-family: var(--font-roboto);
}

.homeInner {
  align-self: stretch;
  flex-direction: row;
  justify-content: center;
  padding: 0 10px 0 0;
  max-width: 100%;
  text-align: center;
  font-size: 72px;
}

.home {
  width: 100%;
  /* height: 100vh; */
  overflow: hidden;
  position: relative;
  background-color: #1c1c1c;
  overflow: hidden;
  flex-direction: column;
  justify-content: flex-start;
  /* padding: 1.875rem 4.75rem 14.375rem; */
  padding: 1.875rem 2.75rem 2rem;
  gap: 4.238rem;
  letter-spacing: normal;
  text-align: left;
  font-size: 1.5rem;
}


/* adding dark mode and light mode */
.rectanglegroup {
  border-radius: 25.5px;
  background-color: #000;
}

.rectanglediv {
  border-radius: 25.5px;
  background-color: #000;
  display: none;
}

.ellipsecontainer {
  background-color: #fff;
  border-radius: 50%;
  position: absolute;
  top: 0.375rem;
  left: 0.375rem;
  width: 1.75rem;
  height: 1.75rem;
}

.framechild1 {
  border-radius: 50%;
  background-color: #fff;
  z-index: 2;
}

.vectoricon {
  position: absolute;
  top: 0.375rem;
  left: 0.438rem;
  width: 0.875rem;
  height: 1rem;
  z-index: 3;
}

@media screen and (max-width : 767px) {
  .rectanglegroup {
    position: absolute;
    width: 3.5rem;
    height: 2.5rem;
    top: 6%;
    left: 70%;
    transform: none;
  }
}

@media screen and (min-width : 768px) and (max-width : 1024px) {
  .rectanglegroup {
    position: absolute;
    width: 3.5rem;
    height: 2.5rem;
    top: 6%;
    left: 77%;
    transform: none;
  }
}

@media screen and (min-width : 1025px) {
  .rectanglegroup {
    position: absolute;
    width: 3.5rem;
    height: 2.5rem;
    top: 5%;
    left: 82%;
    transform: none;
  }
}

@media screen and (max-width: 750px) {
  .signSmartSign {
    font-size: 58px;
  }

  .home {
    gap: 34px 67.8px;
    padding-left: var(--padding-19xl);
    padding-right: var(--padding-19xl);
    box-sizing: border-box;
  }
}

@media screen and (max-width: 450px) {
  .plusDocsChat {
    font-size: var(--font-size-lgi);
  }

  .signSmartSign {
    font-size: 43px;
  }

  .signSmartPlusAIParent {
    gap: 23px 46px;
  }

  .home {
    gap: 17px 67.8px;
  }

}