/* h1{
    font-weight: 900;
    padding: 10px 0;
    font-size: 27px;
    padding-right: 30px;
    pointer-events: none;
    text-align: center;
} */

ul li
{
    list-style: none;
    font-weight: 500;
    color: white;
    margin: 8px;
}

*{
    margin: 0;
    padding: 0;
    box-sizing: border-box;
}
body{
    background-color: #1C1C1C;
}
button{
    text-transform: capitalize;
    padding: 50px 0;
    padding: 14px 29px;
    display: block;
    background-color: white;
    transition: 0.2s ease-out;
    margin: 0;
    border-radius: 72px;
    width: 135px;
    left:-43px;
    position: relative;
}
img.slide-Bar{
    /* width: 50px; */
    height: 30px;
    
}
.input_box
{
    display: none;
    visibility: hidden;
    -webkit-appearance: none;
}

input:checked ~ .toogle .top_line{
    left: 2px;
    top: 14px;
    width: 25px;
    transform: rotate(45deg);
}
input:checked ~ .toogle .middle_line{
    transform: translateX(20px);
    opacity: 0;
}
input:checked ~ .toogle .bottom_line{
    left: 2px;
    top: 14px;
    width: 25px;
    transform: rotate(-45deg);
}

input:checked ~ .slide{
    transform: translatex(0);
    box-shadow: 0 0 15px rgba(0, 0, 0, 0.5);
}

h1 {
    /* color: green; */
    font-weight: 900;
    padding: 10px 0;
    font-size: 27px;
    padding-right: 30px;
    pointer-events: none;
    text-align: center;
}

ul li {
    list-style: none;
    font-weight: 500;
    color: white;
    /* margin: 8px; */
    padding: 1px;
}

* {
    margin: 0;
    padding: 0;
    box-sizing: border-box;
}





button {
    text-transform: capitalize;
    /* padding: 14px 21px; */
    padding: 10px;
    height: 50px;
    width: 55px;
    display: block;
    background-color: white;
    transition: 0.2s ease-out;
    /* margin: 0; */
    margin: 12px;
    border-radius: 72px;
    position: relative;
}

img.slide-Bar {
    width: 30px;
    /* height: 40px; */
}

.input_box {
    display: none;
    visibility: hidden;
    -webkit-appearance: none;
}

.toogle {
    position: absolute;
    width: 30px;
    height: 30px;
    background-color: red;
    z-index: 2; /* Increase z-index to ensure toggle button is above other elements */
    cursor: pointer;
    border-radius: 2px;
    top: 0; /* Align with the top of the page */
    left: 0; /* Align with the left side of the page */
    background-color: #fff;
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.3);
}

.toogle .common {
    position: absolute;
    height: 2px;
    width: 30px;
    background-color: green;
    border-radius: 50px;
    transition: 0.3s ease;
}

.toogle .top_line {
    top: 30%;
    left: 50%;
    transform: translate(-50%, -50%);
}

.toogle .middle_line {
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
}

.toogle .bottom_line {
    top: 70%;
    left: 50%;
    transform: translate(-50%, -50%);
}

input:checked ~ .toogle .top_line {
    left: 2px;
    top: 14px;
    width: 25px;
    transform: rotate(45deg);
}

input:checked ~ .toogle .middle_line {
    transform: translateX(20px);
    opacity: 0;
}

input:checked ~ .toogle .bottom_line {
    left: 2px;
    top: 14px;
    width: 25px;
    transform: rotate(-45deg);
}

input:checked ~ .slide {
    transform: translateX(0);
    box-shadow: 0 0 15px rgba(0, 0, 0, 0.5);
}

.nav-option {
    margin: 25px;
}
.nav-button:disabled {
    opacity: 0.5;
    cursor: not-allowed;
  }
  
  .nav-button:disabled img {
    filter: grayscale(100%);
  }
