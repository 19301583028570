.error-page {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    height: 100vh;
    text-align: center;
    font-family: 'Arial', sans-serif;
  }
  
  .error-page h1 {
    font-size: 3rem;
    color: #5cb85c; 
  }
  
  .error-page p {
    font-size: 1.5rem;
    color: white; 
    margin-top: 10px;
  }
  