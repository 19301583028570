.textbox {
    position: absolute;
    background-color: #1c1c1c;
    font-weight: 900;
    padding: 10px;
    border: 1px solid #000;
  }
  
  button {
    margin-top: 5px;
    padding: 5px 10px;
    color: white;
    border: none;
    cursor: pointer;
  }
  .saved {
    border: 1px solid black;
    background-color: #f1f1f1;
  }
  button {
    margin-top: 10px;
    padding: 8px 15px;
    color: white;
    border: none;
    font-weight: 600;
    cursor: pointer;
  }
  .sign-here-button {
    color: white;
    font-weight: bold;
    margin: 7px;
    text-align: center;
  }
  
  .textbox-container {
    margin-bottom: 10px;
    background-color: #1c1c1c;
    height: 230px;
    width: 249px;
    margin: 24px 51px 36px -17px;
    padding: 22px 20px 21px 12px;
    border-radius: 7px;
  }
  
  .textbox-container input {
    height: 40px;
    margin: 6px;
    width: 223px;
    border-radius: 7px;
    margin: 2px;
  }
  
  .error {
    border-color: red;
  }
  
  .error-message {
    color: red;
  }
  
  .save-button {
    background-color: #00d322;
    height: 50px;
    width: 94px;
    border-radius: 7px;
    margin: 19px;
    top: -7px;
    left: -15px;
  }
  
  .delete-button {
    background-color: #00d322;
    width: 108px;
    height: 50px;
    border-radius: 7px;
    top: -75px;
    left: 97px;
  }
  
  .saved-textbox {
    margin: 3px 2px 9px 0px;
    padding: 9px 24px 25px 38px;
    height: 12px;
    color: white;
    text-align: center;
  }
  
  