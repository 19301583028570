  
  .container {
    display: flex;
    height: 100vh;
  }
  
  .success-message {
    text-align: center;
    background-color: green;
    padding: 20px;
    border-radius: 10px;
    color: white;
  }
  
  .success-message h1 {
    font-size: 2em;
    margin-bottom: 10px;
  }
  
  .success-message p {
    font-size: 1.2em;
    margin-bottom: 20px;
  }
  
  .success-message a {
    display: inline-block;
    background-color: white;
    color: green;
    padding: 10px 20px;
    text-decoration: none;
    border-radius: 5px;
    font-weight: bold;
    transition: background-color 0.3s, color 0.3s;
  }
  
  .success-message a:hover {
    background-color: lightgreen;
    color: green;
  }
  