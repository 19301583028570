.frameChild {
  height: 473px;
  width: 473px;
  position: absolute;
  margin: 0 !important;
  bottom: -139px;
  left: -150px;
  border-radius: 50%;
  background: linear-gradient(148.39deg,
      rgba(0, 211, 34, 0.1),
      rgba(89, 183, 195, 0.1) 50.97%,
      rgba(28, 28, 28, 0.1));
  filter: blur(100px);
}

.frameItem {
  width: 902px;
  height: 362px;
  position: relative;
  border-radius: var(--br-40xl);
  background-color: var(--color-gray-300);
  border: 7px solid var(--color-limegreen);
  box-sizing: border-box;
  display: none;
  max-width: 100%;
  z-index: 0;
}
.downloadIcon {
  width: 219px;
  height: 174px;
  position: absolute;
  margin: 0 !important;
  top: 5px;
  right: 422px;
  overflow: hidden;
  flex-shrink: 0;
  z-index: 1;
  right: auto;
}

.selectAPdf {
  position: relative;
  z-index: 1;
}

.fileChooser {
  height: 77px;
  flex: 1;
  font-family: var(--font-roboto);
  font-weight: 900;
  font-size: var(--font-size-9xl);
  color: var(--color-lightgray);
  width: auto;
  z-index: 1;
}

.fileChooserWrapper,
.rectangleParent {
  box-sizing: border-box;
  display: flex;
  justify-content: flex-start;
}

.fileChooserWrapper {
  width: 314px;
  flex-direction: row;
  align-items: flex-start;
  padding: 0 var(--padding-11xl) 0 var(--padding-10xl);
}

.rectangleParent {
  flex: 1;
  border-radius: var(--br-40xl);
  background-color: var(--color-gray-300);
  border: 7px solid var(--color-limegreen);
  flex-direction: column;
  align-items: center;
  padding: var(--padding-134xl) var(--padding-xl) var(--padding-47xl) var(--padding-3xl);
  position: relative;
  gap: var(--gap-19xl);
  max-width: 100%;
  z-index: 1;
}

.newsAndArticles {
  position: absolute;
  top: 29px;
  left: 0;
  border-radius: 50%;
  background: linear-gradient(148.39deg,
      rgba(0, 211, 34, 0.1),
      rgba(89, 183, 195, 0.1) 50.97%,
      rgba(28, 28, 28, 0.1));
  filter: blur(100px);
  width: 621px;
  height: 621px;
}

.frameInner,
.iconPlus {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
}

.frameInner {
  border-radius: var(--br-6xl-5);
  background-color: var(--color-black);
  display: none;
}

.iconPlus {
  border-radius: 50%;
  background-color: var(--color-white);
  z-index: 2;
}

.innerShapeIcon {
  position: absolute;
  top: 6px;
  left: 7px;
  width: 14px;
  height: 16px;
  z-index: 3;
}

.iconPlusParent,
.rectangleGroup {
  position: absolute;
  top: 6px;
  left: 6px;
  width: 28px;
  height: 28px;
}

.rectangleGroup {
  top: 0px;
  left: 586px;
  border-radius: var(--br-6xl-5);
  background-color: var(--color-black);
  width: 67px;
  height: 40px;
  z-index: 1;
}

.newsAndArticlesParent {
  height: 650px;
  width: 653px;
  position: absolute;
  margin: 130px 178px 38px 28px;
  top: -328px;
  right: -194px;
}

.ellipseParent {
  align-self: stretch;
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  justify-content: flex-start;
  position: relative;
  max-width: 100%;
  text-align: left;
  font-size: var(--font-size-5xl);
  color: var(--color-gray-100);
  font-family: var(--font-roboto);
}

@media screen and (max-width : 360px) {
  .downloadIcon {
    /* right: 103px;
    top: 22px;
    width: 41px; */
    /* right: 79px; */
    top: -22px;
    width: 195px;
    height: 167px;
    opacity: 0.1;
  }

  .selectAPdf {
    width: 169px;
    font-weight: 900;
    font-size: 0.6rem;
  }

  .rectangleParent {
    gap: 19px 38px;
    padding-top: var(--padding-80xl);
    padding-bottom: var(--padding-24xl);
    box-sizing: border-box;
  }
}

@media screen and (min-width : 361px) and (max-width : 450px) {
  .downloadIcon {
    /* width: 50px;
    right: 120px;
    top: 20px; */
    right: 15px;
    top: -24px;
    width: 265px;
    height: 173px;
    opacity: 0.1;
}
  /* } */

  .selectAPdf {
    font-weight: 900;
    font-size: 0.8rem;
  }

  .rectangleParent {
    gap: 19px 38px;
    padding-top: var(--padding-80xl);
    padding-bottom: var(--padding-24xl);
    box-sizing: border-box;
  }

}

@media screen and (min-width : 451px) and (max-width : 767px) {
  .downloadIcon {
    /* right: auto; */
    /* right: 79px; */
    top: 15px;
    width: 265px;
    height: 173px;
    opacity: 0.1;
  }
}

@media screen and (min-width : 768px) {
  .downloadIcon {
    /* right: auto; */
    /* right: 303px; */
    top: 15px;
    width: 365px;
    height: 173px;
    opacity: 0.1;
/* } */
  }
}