.sent-mail-container {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100vh;
}

.message-box {
  text-align: center;
  background-color: #f4f4f4;
  border-radius: 8px;
  max-width: 600px;
  padding: 20px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
}

.message-title {
  font-size: 24px;
  margin-bottom: 10px;
}

.message-text {
  font-size: 22px;
  line-height: 1.5;
  color: #00D322;
  font-weight: bold;
  font-style: initial;
}

.message-text a {
  color: #00D322;
  text-decoration: none;
  cursor: pointer;
}

.message-text a:hover {
  text-decoration: underline;
}
