.sign-docs-container{
  background-color:#1c1c1c;
}
.loading-text,
.error-text {
  font-size: 18px;
}


.custom-pagination button {
  cursor: pointer;
  padding: 10px;
  margin: 0 5px;
  background-color: #2c7e64;
  color: #fff;
  font-weight: bold;

  border: none;
  border-radius: 4px;
  outline: none;
}

.custom-pagination button:disabled {
  background-color: #ccc;
  cursor: not-allowed;
}

.page-info {
  font-size: 16px;
  margin: 0;
}

.react-pdf__Page{
  background-color: white;
  min-width:500px;
  position: relative;
  min-height: 532px;
  --scale-factor:1;
  width: 763px;
  border-radius: 10px;
}
.popup-container {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 1000;
}

.popup-content {
  padding: 20px;
  border-radius: 24px;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.3);
  max-height: 475px;
  max-width: 450px;
  background: white;
}
.react-pdf__Page__canvas{
  margin: 0;
  display: block;
  width: 561px;
}

.close-button {
  cursor: pointer;
  background: #ff6347;
  color: white;
  border: none;
  padding: 10px;
  border-radius: 4px;
  margin-bottom: 10px;
}
.react-pdf__Page__textContent {
  display: none;
}

@keyframes fadeIn {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}

.custom-pagination button {
  position: relative;
  cursor: pointer;
  padding: 10px;
  margin: 0 5px;
  background-color: #2c7e64;
  color: #fff;
  font-weight: bold;
  border: none;
  border-radius: 4px;
  outline: none;
  overflow: hidden;
  transition: background-color 0.3s ease-in-out;
}

.custom-pagination button:hover {
  background-color: #1d5e4b;
}

.custom-pagination button:disabled {
  background-color: #ccc;
  cursor: not-allowed;
}

.custom-pagination button::before {
  content: '';
  position: absolute;
  top: 0;
  left: 0;
  width: 0;
  height: 100%;
  background-color: #52b788;
  transition: width 0.3s ease-in-out;
}

.custom-pagination button:hover::before {
  width: 100%;
}

.page-info {
  font-size: 16px;
  margin: 0;
}

.custom-pagination {
  position: absolute;
  top: 285px;
  left: 50px;
  display: flex;
  padding: 1px;
  border-radius: 50px;
  animation: fadeIn 0.5s ease-in-out;
  flex-direction: column;
  gap: 10px;
}

.custom-pagination button {
  cursor: pointer;
  background-color:white;
  color: #fff;
  font-weight: bold;
  border: none;
  border-radius: 50%;
  outline: none;
  margin: 17px 9px 14px 53px;
  height: 60px;
  width: 60px;
  padding: 5px 0px 3px 1px;
  transition: background-color 0.3s ease;
}

.custom-pagination button:hover {
  background-color: #256a57;
}

.custom-pagination button:disabled {
  background-color:rgb(56 51 51 / 50%);
  cursor: not-allowed;

  margin: 1px 4px 6px 52px;
}

.close-button {
  cursor: pointer;
  background: #ff6347;
  color: white;
  border: none;
  padding: 10px;
  border-radius: 4px;
  width: 90px;
  left: -17px;
  top: -16px;
  margin-bottom: 10px;
}

.pdf-show{
  margin-top: 50px;
  position: relative;
  left: 111px;
  top: -56px;

}
.custom-pagination img {
  width: 30px; 
  height: 30px;
}

.react-pdf__Page__textContent {
  display: none;
}


@keyframes fadeIn {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}

.header-sign h1 {
  color: #fff; 
  padding: 15px; 
  margin: 0; 
  font-size: 24px; 
  font-weight: bold; 
}
.wrapper-class{
  width: 6.813rem;
  border: 3px solid #00d322;
  height: 2.5rem;
  background-color: #1c1c1c;
  display: flex;
  position: absolute;
  top: 130px;
  left: 44px;
  align-items: center;
  border-radius: 39.5px;
}


.flexiable{
  display: flex;
  width: 100%;
}