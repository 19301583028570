@import url("https://fonts.googleapis.com/css2?family=Roboto:wght@700;900&display=swap");
@import url("https://fonts.googleapis.com/icon?family=Material+Icons");

body {
  margin: 0;
  line-height: normal;
  background-color: #1C1C1C !important;
}
:root {
  /* fonts */
  --font-roboto: Roboto;

  /* font sizes */
  --font-size-9xl: 28px;
  --font-size-5xl: 24px;
  --font-size-lgi: 19px;
  --font-size-53xl: 72px;
  --font-size-24xl: 43px;
  --font-size-39xl: 58px;

  /* Colors */
  --color-white: #fff;
  --color-black: #000;
  --color-gray-100: #919191;
  --color-gray-200: rgba(28, 28, 28, 0);
  --color-gray-300: rgba(255, 255, 255, 0);
  --color-limegreen: #00d322;
  --color-lightgray: #cbcbcb;

  /* Gaps */
  --gap-mid: 17px;
  --gap-15xl: 34px;
  --gap-27xl: 46px;
  --gap-4xl: 23px;
  --gap-19xl: 38px;
  --gap-lgi: 19px;

  /* Paddings */
  --padding-27xl: 46px;
  --padding-57xl: 76px;
  --padding-211xl: 230px;
  --padding-19xl: 38px;
  --padding-3xs: 10px;
  --padding-134xl: 153px;
  --padding-xl: 20px;
  --padding-47xl: 66px;
  --padding-3xl: 22px;
  --padding-80xl: 99px;
  --padding-24xl: 43px;
  --padding-11xl: 30px;
  --padding-10xl: 29px;
  --padding-8xs: 5px;
  --padding-4xl: 23px;

  /* Border radiuses */
  --br-6xl-5: 25.5px;
  --br-40xl: 59px;
}
